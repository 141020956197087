
:root {
  --color-primary: #d82953;
  --color-secondary: #0e1d39;

  --color-warning: #c6244b;
  --color-success: #53D829;
  --color-text: #0e1d39;
  --color-bg: #e0f1fe;
}

body,
html {
  padding: 0;
  margin: 0;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  color: var(--color-text);
}

* {
  box-sizing: border-box;
}


/* Page Styles */

.page {
  min-height: 100vh;
  padding: 40px 24px;
  text-align: center;
}

.page.match-success {
  background-color: var(--color-success);
  /* TODO: Fade out animation */
}

.page.match-failure {
  /* TODO: Blink animation */
  background-color: var(--color-warning);
}

.page-title {
  margin: 0 0 32px;
  font-size: 1.5rem;
}

.input-group {
  max-width: 300px;
  margin-inline: auto;
  margin-bottom: 32px;
}

.btn-clear-all {
  display: block;
  width: 100%;
  padding: 12px 16px;
  text-align: center;
  border: 0;
  background-color: var(--color-secondary);
  color: #fff;
  font-size: 1rem;
  font-family: inherit;
  font-weight: 600;
}

.result-output {
  text-align: center;
}

.result-text {
  font-size: 3rem;
  line-height: 1.25em;
  font-weight: 700;
}
