.feed-input-wrapper {
  display: block;
  width: 100%;
  max-width: 300px;
  margin-bottom: 24px;
  text-align: left;
}

.feed-input-label {
  display: block;
  font-size: 0.75rem;
  margin-bottom: 4px;
}

.feed-input {
  width: 100%;
  padding: 12px 16px;
  font-size: 1.25rem;
  color: inherit;
  border: 1px solid var(--color-secondary);
}